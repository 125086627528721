import { Gasthof, Hero, Location, Service, Speisekarte, Video, Zimmer } from './components';
import React, { Component } from 'react';

import Helmet from 'react-helmet';
import styles from './Home.module.scss';

const seo = {
  titleDE:        <title> Landgasthof Sonne Haldenwang </title>,
  descriptionDE:  <meta name="description" content='Regionale Küche, kroatische Spezialitäten und vieles vieles mehr erwartet Sie bei uns im Landgasthof Sonne in Haldenwang im Allgäu.' />,
}

class Home extends Component {

  componentDidMount() {
    window.scroll(0,0)
  }

  renderHelmet = () => {
    return (
      <Helmet htmlAttributes={{ lang: 'de'}}> {seo.titleDE} {seo.descriptionDE} </Helmet>
    )
  }

  render() {

    return (
      <div className={styles.wrapper}>
        {this.renderHelmet()}
        
        <Hero />
        <Video />
        <div className={styles.divider_left} />
        <Speisekarte />
        <div className={styles.divider_right} />
        <Gasthof />
        <div className={styles.divider_left} />
        <Zimmer />
        <div className={styles.divider_right} />
        <Service />
        <div className={styles.divider_left} />
        <h2 className={styles.location} id="anfahrt">
          Sie finden uns in Haldenwang im Allgäu!
        </h2>
        <div className={styles.divider_right} />
        <Location />
        
      </div>
    );
  }
}

export default Home;
