import React, { Component } from 'react';
import styles from './Zimmer.module.scss';
import Helmet from 'react-helmet';
import { Hero, Details } from './components'

const seo = {
  titleDE:        <title> Unsere Gästezimmer </title>,
  descriptionDE:  <meta name="description" content='Modern eingerichtet, ausgestattet mit Sat-TV, Dusche, WC sowie kostenfreiem Internet. Zudem zentral gelegen und damit der perfekte Ausgangspunkt für Ausflüge jeglicher Art.' />,
}

class Zimmer extends Component {

  componentDidMount() {
    window.scroll(0,0)
  }

  renderHelmet = () => {
    return (
      <Helmet htmlAttributes={{ lang: 'de'}}> {seo.titleDE} {seo.descriptionDE} </Helmet>
    )
  }

  render() {

    return (
      <div className={styles.background}>
        {this.renderHelmet()}

        <Hero />
        <Details />

      </div>
    );
  }
}

export default Zimmer;