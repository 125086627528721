import { Col, Drawer, Icon, Row } from 'antd';
import { Link, NavLink } from 'react-router-dom';
import React, { Component } from 'react';

import MediaQuery from 'react-responsive';
import hamburger from '../../assets/images/hamburger.svg';
import logo_pic from '../../assets/images/logo.jpg';
import styles from './Header.module.scss';

class Header extends Component {
  constructor() {
    super();
      this.state = {
        visible: false
      }
    this.showDrawer = this.showDrawer.bind(this);
    this.onClose= this.onClose.bind(this);
    this.renderLinks= this.renderLinks.bind(this);
  }

  showDrawer () {
    this.setState({
      visible: true,
    });
  };

  onClose() {
    this.setState({
      visible: false,
    });
  };

  scrollDown = () => {
    this.setState({
      visible: false,
    }); 
    setTimeout(function(){  
      document.getElementById("anfahrt").scrollIntoView({behavior: 'smooth', block: 'start'})
    }, 100);
  };

  renderLinks(x) {
    const style = x ? styles.links_mobile : styles.links
    return (
      <nav className={style}>
        <NavLink to='/spezialitaeten' onClick={this.onClose}>Speisekarte</NavLink>
        <NavLink to='/gasthof' onClick={this.onClose}>Gasthof</NavLink>
        <NavLink to='/zimmer' onClick={this.onClose}>Zimmer</NavLink>
        <NavLink to='/bildergalerie' onClick={this.onClose}>Bilder</NavLink>
        <NavLink to='/#anfahrt' onClick={this.scrollDown}>Anfahrt</NavLink>
        <NavLink to='/jobs' onClick={this.onClose}>Jobs</NavLink>
        <NavLink to='/kontakt' onClick={this.onClose}>Kontakt</NavLink>
      </nav>
    )
  }

  render() {

    const logo = (
      <Link to='/'> 
        <div className={styles.logo}>
          <img src={logo_pic} alt='description' /> 
        </div>
      </Link>
    )

    const logo_mobile = (
      <Link to='/'> 
        <div className={styles.logo_mobile} onClick={this.onClose}>
          <img src={logo_pic} alt='description' /> 
        </div>
      </Link>
    )
      
      
    const button = (
      <span><Icon type="phone" /> +49 (0)8374 / 2323811</span>
    )

    return (
      // Responsive Header
      <div className={styles.background}>
        <Row 
          type='flex' align='middle' justify='space-between'
          className={styles.wrapper}>

            {/* Logo */}
            <Col xs={6} sm={6} lg={6} align='start'
              className={styles.Logo}>
                {logo}
            </Col>

            {/* Links */}
            <MediaQuery minDeviceWidth={1024}>
              <Col span={18} justify='flex-end'
              className={styles.links_col}>
                {this.renderLinks()}
              </Col>
            </MediaQuery>
            
            {/* Button 
            <Col xs={6} sm={6} lg={6} align='start'
              className={styles.button}>
                {button}
            </Col>*/}

            {/* Hamburger Menu */}
            <MediaQuery maxDeviceWidth={1023}>
              <Col span={12} className={styles.Hamburger}>
                <img src={hamburger} alt='description'
                  className={styles.menu_bars}
                  onClick={this.showDrawer}/>
              </Col>
            </MediaQuery>

        </Row>

        {/* Mobile Menu / Drawer */}
        <Drawer
          title={logo_mobile}
          placement='left'
          closable='true'
          onClose={this.onClose}
          visible={this.state.visible}
          >
            <div className={styles.drawer_content}>
              {this.renderLinks('mobile')}
            </div>
        </Drawer>
      </div>
    )
  }
}

export default Header;