import { Row } from "antd"
import React, { Component } from "react"
import ReactPlayer from "react-player"
import styles from "./video.module.scss"
import play from "../../../../assets/images/play.svg"

// Necessary Imports

class Video extends Component {
  state = { playing: false }

  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing })
  }

  render() {
    const { playing } = this.state
    return (
      <div className={styles.background}>
        <Row type="flex" align="middle" className={styles.wrapper}>
          <div className={styles.video_wrapper}>
            <ReactPlayer
              url="https://sonne-haldenwang.s3.eu-central-1.amazonaws.com/2019-03-18+MyKlickCard+Landgasthof+Sonne+3-Haldenwang+(1).mp4"
              controls
              playing={playing}
              width="100%"
              height="100%"
              className={styles.video}
            />
          </div>
          {playing ? (
            ""
          ) : (
            <img
              src={play}
              alt="Play"
              onClick={this.handlePlayPause}
              className={styles.button}
            />
          )}
        </Row>
      </div>
    )
  }
}

export default Video
