import React, { Component } from 'react';
import styles from './details.module.scss';
import { Card } from 'antd';
import { Link } from 'react-router-dom';

// Necessary Imports
import { Row, Col, Button } from 'antd';


class Details extends Component {

  render() {

    return (
      <div id='zimmer' className={styles.background}>
        <Row 
        type='flex' align='middle' 
        className={styles.wrapper}>
          <Col 
          xs={24} sm={16} lg={11}
          className={styles.content}>
            <h2 className={styles.headline}>
              Modern, Gemütlich, Zentrale Lage!
            </h2>
            <p className={styles.text}>
              Wandern, Baden, Skifahren, Langlaufen, verschiedene Sehenswürdigkeiten
              und noch vieles mehr erwartet Sie bei uns Im Allgäu.
              Geschäftsreisende finden hier zudem einen Idealen Ausgangspunkt
              für neue geschäftliche Kontakte.<br/><br/>
              Frühstück an SA/SO und Feiertagen gegen Aufpreis möglich.
              Für Fragen stehen wir Ihnen jederzeit
              gerne zur Verfügung.
            </p>
            <Link to='/kontakt'>
              <Button 
                type='primary'
                size='large'
                className={styles.button}>
                Jetzt Anfragen
              </Button>
            </Link>
          </Col>
          <Col 
          xs={24} sm={16} lg={{span: 11, offset: 2}}
          className={styles.image}>
            <Card 
            hoverable
            className={styles.card}>
            <div className={styles.preise}>
              <p>Einzelzimmer</p>
              <span>65,- € (ohne Frühstück)</span>
            </div>
            </Card>
            <Card 
            hoverable
            className={styles.card}>
              <div className={styles.preise}>
              <p>Doppelzimmer</p>
              <span>77,- € (ohne Frühstück)</span>
            </div>
            <div className={styles.preise}>
              <p>(mit Balkon, Badewanne)</p>
              <span>87,- € (ohne Frühstück)</span>
            </div>
            </Card>
            <Card 
            hoverable
            className={styles.card}>
              <div className={styles.preise}>
              <p>Kinder<br/> im Zimmer der Eltern</p>
              <span>
                bis 6 Jahre frei!<br/>
                bis 12 Jahre 15,- € <br/>
                bis 16 Jahre 22,- €<br/>
              </span>
            </div>
            </Card>
            <Card 
            hoverable
            className={styles.card}>
              <div className={styles.preise}>
              <p>Zusatzleistungen</p>
              <span>
                Haustiere: 5,- € / Tag<br/>
                Frühstück: 10,- € / Tag<br/>
              </span>
            </div>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Details;
 