import { Button, Col, Row } from 'antd';
import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import image from './../../../../assets/images/home_speisen.jpg';
import styles from './speisekarte.module.scss';

// Necessary Imports



class Speisekarte extends Component {

  render() {

    return (
      <div className={styles.background}>
        <Row 
        type='flex' align='middle' 
        className={styles.wrapper}>
          <Col 
          xs={24} sm={16} lg={11}
          className={styles.content}>
            <h2 className={styles.headline}>
              Unsere Spezialitäten
            </h2>
            <p className={styles.text}>
            Lassen Sie sich nicht nur aus unserer frischen, regionalen Küche verwöhnen, 
            sondern genießen Sie auch unsere kroatischen Spezialitäten.
            </p>
            <Link to='/spezialitaeten'>
              <Button 
                type='primary'
                size='large'
                className={styles.button}>
                Zur Speisekarte
              </Button>
            </Link>
          </Col>
          <Col 
          xs={24} sm={16} lg={{span: 11, offset: 2}}
          className={styles.image}>
            <img src={image} alt='description' />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Speisekarte;
 