import {observable, decorate} from 'mobx';

class DataStore {
  
  language = 'de';

}
decorate(DataStore, {
	language: observable
});
export default DataStore