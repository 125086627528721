import { Col, Icon, Row } from "antd"
import React, { Component } from "react"

import { Link } from "react-router-dom"
import logo from "./../../assets/images/logo.jpg"
import styles from "./Footer.module.scss"

const content = {
  company: "Company Name",
  company_street: "Hauptstraße 10",
  company_location: "87490 Haldenwang",

  contact: "Kontakt",
  contact_phone: "08374/2323811",
  contact_fax: "08374/2323812",
  contact_email: "info@sonne-haldenwang.de",

  hours: "Öffnungszeiten",
  first: "Mo - Di: Ruhetag",
  second: "Mi - Sa: 17:00-00:00 Uhr",
  third: "Sonntag: 11:00-14 Uhr & 17:00-24 Uhr",
  fourth: "Feiertage: 11:00-14 Uhr & 17:00-24 Uhr",

  legal: "Rechtliches",
  legal_cookies: "Cookie Richtlinie",
  legal_privacy: "Datenschutz",
  legal_impressum: "Impressum",
}

class Footer extends Component {
  render() {
    return (
      <div className={styles.background}>
        <Row type="flex" justify="space-between" className={styles.footer}>
          <Col md={7} className={styles.company}>
            <img src={logo} alt="logo" className={styles.logo} />
            <ul>
              <li> {content.company_street} </li>
              <li> {content.company_location} </li>
            </ul>
          </Col>

          <Col md={6} className={styles.contact}>
            <p> {content.contact} </p>
            <ul>
              <li> {content.contact_availability} </li>
              <li>
                {" "}
                <Icon type="phone" /> {content.contact_phone}{" "}
              </li>
              <li>
                {" "}
                <Icon type="file-text" /> {content.contact_fax}{" "}
              </li>
              <li>
                {" "}
                <a href="mailto:info@sonne-haldenwang.de">
                  {" "}
                  <Icon type="mail" /> {content.contact_email}{" "}
                </a>
              </li>
            </ul>
          </Col>

          <Col md={6} className={styles.hours}>
            <p> {content.hours} </p>
            <ul>
              <li> {content.first} </li>
              <li> {content.second} </li>
              <li> {content.third} </li>
              <li> {content.fourth} </li>
            </ul>
          </Col>

          <Col md={5} className={styles.legal}>
            <p> {content.legal} </p>
            <ul>
              <li>
                <Link to="/cookies"> {content.legal_cookies} </Link>
              </li>
              <li>
                <Link to="/datenschutz"> {content.legal_privacy} </Link>
              </li>
              <li>
                <Link to="/impressum"> {content.legal_impressum} </Link>
              </li>
            </ul>
          </Col>
        </Row>

        <Row className={styles.creator}>
          Made with &#10084; by
          <a
            href={"https://www.weborilla.de"}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.creatorInfo_logo}
          >
            {" "}
            Weborilla{" "}
          </a>
        </Row>
      </div>
    )
  }
}

export default Footer
