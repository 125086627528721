import { Checkbox, Col, Row } from 'antd';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { changeCookieConsent } from '../../../utils/Cookies';
import styles from './Popup.module.scss';

const content = {
  header:   'Diese Webseite verwendet Cookies!',
  text:     <span>Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies. 
            Ausführliche Informationen hierzu finden Sie in unserer <Link to='/cookies'>Cookie Richtlinie</Link></span>,
  allow:    'Cookies zulassen',
  decline:  'Nur wesentliche Cookies '
}

class CookiePopUp extends Component {

  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = { checked: false };
  }

  changeConsent = (e) => {
    this.setState({ checked: e.target.checked });
  }

  setCookieConsent = (x) => {
    if (x === true && this.state.checked === true ) {
      changeCookieConsent(true)
    } else {
      changeCookieConsent(false)
    }
  }

  render() {

    return (
      <div className={styles.cookies}>
        <div className={styles.cookies_header}>
          <p>{content.header}</p>
        </div>
        <p>{content.text}</p>
        <Row type='flex' justify='start'
        className={styles.consent}>
          <Col className={styles.consent_col}>
            <Checkbox checked='true' disabled='true'>Wesentlich</Checkbox>
            <Checkbox checked={this.state.checked} onChange={this.changeConsent}>Statistisch</Checkbox>  
          </Col>
          <Col className={styles.consent_col}>
            <Checkbox>Komfort</Checkbox>
            <Checkbox>Marketing</Checkbox>
          </Col>
        </Row>
        <div className={styles.links}>
          <Button size='large' type='primary' 
          className={styles.cookies_button} 
          onClick={() => this.setCookieConsent(true)}>
            {content.allow}
          </Button>
          <a className={styles.cookies_decline} 
          onClick={() => this.setCookieConsent(false)}>
            {content.decline}
          </a>
        </div>
      </div>
    );
  }
}

export default inject('store')(observer(CookiePopUp)); 