import { Row, Col, Button, Modal } from "antd"
import React, { Component } from "react"
import { Link } from "react-router-dom"
import styles from "./hero.module.scss"

const content = {
  header: "Landgasthof Sonne Haldenwang",
  text: "Herzlich Willkommen bei uns im Landgasthof Sonne, im Herzen von Haldenwang im Allgäu. Wir freuen uns auf Ihren Besuch!",
  button: "Zur Speisekarte",
  button_two: "Öffnungszeiten",
}

class hero extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  handleOk = () => {
    this.setState({ visible: false })
  }

  handleCancel = () => {
    this.setState({ visible: false })
  }

  render() {
    const { visible } = this.state

    return (
      <div className={styles.background}>
        <Modal
          visible={visible}
          title={<strong>Öffnungszeiten - Landgasthof Sonne</strong>}
          maskClosable
          closable
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="submit" type="primary" onClick={this.handleOk}>
              Okay
            </Button>,
          ]}
        >
          <div>
            <div className={styles.times}>
              <span>
                <strong>Warme Küche</strong>
              </span>
              <span>
                <strong>11:30 - 14:00 Uhr | 17:30 - 21:00 Uhr</strong>
              </span>
            </div>
            <div className={styles.time}>
              <span>Montag</span>
              <span>Ruhetag</span>
            </div>
            <div className={styles.time}>
              <span>Dienstag</span>
              <span>Ruhetag</span>
            </div>
            <div className={styles.time}>
              <span>Mittwoch</span>
              <span>17:00 - 00:00 Uhr</span>
            </div>
            <div className={styles.time}>
              <span>Donnerstag</span>
              <span>17:00 - 00:00 Uhr</span>
            </div>
            <div className={styles.time}>
              <span>Freitag</span>
              <span>17:00 - 00:00 Uhr</span>
            </div>
            <div className={styles.time}>
              <span>Samstag</span>
              <span>17:00 - 00:00 Uhr</span>
            </div>
            <div className={styles.time}>
              <span>Sonntag</span>
              <span>11:00 - 14:00 Uhr | 17:00 - 00:00 Uhr</span>
            </div>
          </div>
        </Modal>

        <Row type="flex" align="middle" className={styles.wrapper}>
          <Col xs={24} sm={16} lg={16} className={styles.content}>
            <h1 className={styles.headline}>{content.header}</h1>
            <p className={styles.text}>{content.text}</p>
            <Link to="/spezialitaeten">
              <Button size="large" type="primary" className={styles.button}>
                {content.button}
              </Button>
            </Link>
            <Button
              size="large"
              className={styles.button_two}
              onClick={this.showModal}
            >
              {content.button_two}
            </Button>
          </Col>
        </Row>
      </div>
    )
  }
}

export default hero
