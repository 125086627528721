import React, { Component } from 'react';
import styles from './Gasthof.module.scss';
import Helmet from 'react-helmet';
import { Hero, Spielzimmer, Restaurant, Biergarten } from './components'

const seo = {
  titleDE:        <title> Unser Landgasthof </title>,
  descriptionDE:  <meta name="description" content='Gemütliche Atmosphäre, sonniger Biergarten, ein Spielzimmer für die kleinen Gäste. Unser Landgasthof bietet Ihnen die perfekten Räumlichkeiten für einen gemütlichen Besuch oder Festlichkeiten mit bis zu 80 Personen.' />,
}

class Gasthof extends Component {

  componentDidMount() {
    window.scroll(0,0)
  }

  renderHelmet = () => {
    return (
      <Helmet htmlAttributes={{ lang: 'de'}}> {seo.titleDE} {seo.descriptionDE} </Helmet>
    )
  }

  render() {

    return (
      <div className={styles.background}>
        {this.renderHelmet()}

        <Hero />
        <Restaurant />
        <div className={styles.divider_right} />
        <Spielzimmer />
        <div className={styles.divider_left} />
        <Biergarten />

      </div>
    );
  }
}

export default Gasthof;