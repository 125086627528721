import {observable, decorate} from 'mobx';

class CookieStore {
  
  enabled = null;

}
decorate(CookieStore, {
	enabled: observable
});
export default CookieStore
