import React, { Component } from 'react';
import styles from './zimmer.module.scss';
import { Link } from 'react-router-dom';
import image from './../../../../assets/images/home_zimmer.jpg';

// Necessary Imports
import { Row, Col, Button } from 'antd';


class Zimmer extends Component {

  render() {

    return (
      <div className={styles.background}>
        <Row 
        type='flex' align='middle' 
        className={styles.wrapper}>
          <Col 
          xs={24} sm={16} lg={11}
          className={styles.content}>
            <h2 className={styles.headline}>
              Unsere Gästezimmer
            </h2>
            <p className={styles.text}>
            Unsere modern ausgestatteten Zimmer verfügen alle
            über Sat-TV, Dusche, WC sowie über einen kostenfreien
            Internet-Zugang via Lan und W-Lan.
            </p>
            <Link to='/zimmer'>
              <Button 
                type='primary'
                size='large' 
                className={styles.button}>
                Mehr erfahren
              </Button>
            </Link>
          </Col>
          <Col 
          xs={24} sm={16} lg={{span: 11, offset: 2}}
          className={styles.image}>
            <img src={image} alt='description' />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Zimmer;
 