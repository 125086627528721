import { Button, Col, Row } from 'antd';
import React, { Component } from 'react';

import styles from './speisen.module.scss';

class hero extends Component {

  render() {

    return (
      <div className={styles.background}>
        <Row 
        type='flex' apgn='middle' 
        className={styles.wrapper}>
          <Col 
          xs={24} sm={16} lg={16}
          className={styles.content}>

            <div id='spezialitaeten'>
              <h3>
                Vorspeisen
              </h3>
              <p>PRSUT</p>
              <span>Dalmatinischer luftgetrockneter Schinken mit Olivenöl, Peperoni, Oliven und Zwiebelringen garniert, dazu servieren wir Weißbrot</span>
              <p>VORSPEISENPLATTE für ZWEI</p>
              <span>Luftgetrockneter Schinken mit würzigem Käse, Olivenöl, Oliven, Peperoni, Zwiebelringen und Weißbrot</span>
              <p>GEBRATENE PAPRIKA</p>
              <span>mit Schafskäsebrösel und Weißbrot</span>
              <p>KÄSESTICKS</p>
              <span>mit Speckmantel auf Tomatencarpaccio und Salatgarnitur</span>
            </div>

            <div>
              <h3>
                SalatBuffet
              </h3>
              <p>Täglich frisch</p>
              <span>bieten wir Ihnen eine große Auswahl an unserem Salatbuffet</span>
            </div>

            <div>
              <h3>
                Spezialitäten des Hauses
              </h3>
              <p>MARINIERTE HÄHNCHENBRUST</p>
              <span>mit Rosmarin, Djuvecreis und Tomatensoße</span>
              <p>HÄHNCHENSPIEß Á LA GOURMET</p>
              <span>Saftiges Hähnchenspieß mit Garnelen im Speckmantel, Tomatensoße und Djuvecreis</span>
            </div>

            <div>
              <h3>
                Frische, selbst gemachte Pasta
              </h3>
                <p>RAVIOLI</p>
                <span>mit Spinat Füllung und Fetawürfel in Sahnesauce, Parmesan</span>
                <p>MOZZARELLATEIGTASCHEN</p>
                <span>mit leichter Tomaten-Sahne-Sauce und gebratenem Gemüse</span>
            </div>

            <div>
              <h3>
                Für Veganer empfohlen
              </h3>
              <p>RAVIOLI</p>
              <span>mit Mediterrane Rafinnesse (versch. Gemüse nach Saison)</span>
            </div>

            <div>
              <h3>
                Frisch vom Grill
              </h3>
                <p>CEVAPCICI</p>
                <span>Hackfleischröllchen mit Djuvecreis und Pommes frites, Ajvar, frische Zwiebeln und Peperoni</span>
                <p>LUSTIGER BOSNJAK</p>
                <span>Hackfleischsteak mit Schafskäse gefüllt, dazu Djuvecreis und Pommes frites, Ajvar, frische Zwiebeln und Peperoni</span>
                <p>RAZNJICI</p>
                <span>3 Fleischspieße mit Djuvecreis, Ajvar, frische Zwiebeln und Peperoni</span>
                <p>POLA POLA</p>
                <span>Hackfleischsteak mit Schafskäse gefüllt, 2 Fleischspieße, mit Pommes frites Ajvar, frische Zwiebeln und Peperoni</span>
                <p>DUO TELLER</p>
                <span>Schweinekotelett und Hackfleischsteak mit Djuvecreis, dazu Pommes frites, Ajvar frische Zwiebeln und Peperoni</span>
                <p>TRIO TELLER</p>
                <span>Schweinekotelett mit Hackfleischsteak und Fleischspieß mit Djuvecreis, Ajvar, frische Zwiebeln und Peperoni</span>
                <p>SCHWEINELEBER "CROATIA"</p>
                <span>würzig gegrillte Schweineleber mit Kräuterkartoffeln, Ajvar, frische Zwiebeln und Peperoni</span>
                <p>CROATIA PLATTE</p>  
                <span>Schweinekotelett, Fleischspieß, Schweineleber Hackfleischsteak mit Schafskäse gefüllt, Cevapcici mit Djuvecreis und Pommes frites, Ajvar, frische Zwiebeln und Peperoni</span>
                <p>HERRENTRIO</p>
                <span>Schweinekotelett, Schweineleber, Cevapcici mir Kräuterkartoffeln, Ajvar, frische Zwiebeln und Peperoni</span>
            </div>

            <div>
              <h3>
                Frisch aus der Pfanne
              </h3>
              <p>SCHWEINESCHNITZEL</p>
              <span>"WIENER ART" mit Pommes frites</span>
              <p>CORDON BLEU</p>
              <span>vom Schwein mit Pommes frites</span>
              <p>SCHNITZEL D. HAUSES</p>
              <span>mit Speck, Senf und Zwiebeln gefüllt</span>
            </div>

            <div>
              <h3>
                Fisch
              </h3>
              <p>DORADE</p>
              <span>vom Grill mit Spinatkartoffeln und feinem Olivenöl</span>
              <p>ZANDERFILET</p>
              <span>mit fetuccine mediterrán und frischem parmesan</span>
            </div>

            <div>
              <h3>
                Nudelgerichte
              </h3>
              <p>FETUCCINE</p>
              <span>mit Lauch, Champignon, Rahmsauce und frischem Parmesan</span>
              <p>FETUCCINE</p>
              <span>mit gegrilltem Gemüse und frischem Parmesan</span>
            </div>

            <div>
              <h3>
                Dessert
              </h3>
              <p>Palatschinken</p>
              <span>Frische Hausgemachte Palatschinken in vielen Variationen</span>
            </div>

            <div className={styles.info}>
              Alle Angaben ohne Gewähr!<br/>
              Eine Übersicht über Allergene und Zusatzstoffe, liegt im Restaurant jeder Karte bei.
            </div>

          </Col>
        </Row>
      </div>
    );
  }
}

export default hero;
 