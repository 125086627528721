import React, { Component } from 'react';
import styles from './biergarten.module.scss';
import { Link } from 'react-router-dom';

import image from './../../../../assets/images/gasthof_biergarten.jpg';

// Necessary Imports
import { Row, Col, Button } from 'antd';


class Biergarten extends Component {

  render() {

    return (
      <div className={styles.background}>
        <Row 
        type='flex' align='middle' 
        className={styles.wrapper}>
          <Col 
          xs={24} sm={16} lg={11}
          className={styles.content}>
            <h2 className={styles.headline}>
              Sonnenterasse
            </h2>
            <p className={styles.text}>
            Bei gutem Wetter können Sie unsere Spezialitäten wahlweise auch an der frischen Luft genießen. 
            Unsere Sonnenterasse ist der perfekte Platz dafür.
            </p>
          </Col>
          <Col 
          xs={24} sm={16} lg={{span: 11, offset: 2}}
          className={styles.image}>
            <img src={image} alt='description' />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Biergarten;
 