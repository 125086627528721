import { Row, Col } from 'antd';
import React, { Component } from 'react';
import image from './../../../../assets/images/gasthof_spielzimmer.jpg';
import styles from './spielzimmer.module.scss';

class Spielzimmer extends Component {

  render() {

    return (
      <div className={styles.background}>
        <Row 
        type='flex' align='middle' 
        className={styles.wrapper}>
          <Col 
          xs={24} sm={16} lg={11}
          className={styles.image}>
            <img src={image} alt='description' />
          </Col>
          <Col 
          xs={24} sm={16} lg={{span: 11, offset: 2}}
          className={styles.content}>
            <h2 className={styles.headline}>
              Spielzimmer
            </h2>
            <p className={styles.text}>
              Bei uns kommen auch die kleinen Gäste Voll und Ganz auf Ihre Kosten. 
              Denn unser Spielezimmer garantiert Spiel, Spaß und Spannung für Kinder (fast) jeden Alters.
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Spielzimmer;
 