import React, { Component } from 'react';
import styles from './Jobs.module.scss';
import Helmet from 'react-helmet';

const seo = {
  titleDE:        <title> Jobs - Sonne Haldenwang  </title>,
  descriptionDE:  <meta name="description" content='Werden Sie Teil unseres Teams! Wir suchen Sie für unser Team im Landgasthof Sonne in Haldenwang.' />
}

class Jobs extends Component {

  componentDidMount() {
    window.scroll(0,0)
  }

  renderHelmet = () => {
    return (
      <Helmet htmlAttributes={{ lang: 'de'}}> {seo.titleDE} {seo.descriptionDE} </Helmet>
    )
  }

  render() {

    return (
      <div className={styles.background}>
        {this.renderHelmet()}
        <div className={styles.header}>
          <div className={styles.wrapper}>
            <h1>Werden Sie Teil unseres Teams!</h1>
            <p>Wir suchen Servicekräfte (520,-€ Basis & Teilzeit)</p>
          </div>
        </div>
        <div className={styles.wrapper}>
        <p className={styles.firstLine}>Für unseren Familienbetrieb suchen wir:</p>
        <p className={styles.job}>- Servicekraft (m/w/d) auf 520,00 € Basis</p>
        <p className={styles.job}>- Servicekraft (m/w/d) in Teilzeit</p>
        <br/>
        <p>
        Unsere Gäste kommen immer wieder gerne zu uns zurück,
        weil wir hochwertige Produkte aus der Region zu leckeren Menüs verarbeiten und immer einen sehr freundlichen Service pflegen.

        Nun brauchen wir Sie,
        da wir unser Team vergrößern wollen.
        </p>
        <br/>
        <p className={styles.jobHeader}>Was wir von Ihnen erwarten:</p>
        <p>Ausbildung zur Servicekraft (nicht zwingend erforderlich)<br/>
        Erfahrung in der Gastronomie<br/>
        Kenntnisse in der Küche<br/>
        Kenntnisse in der Kundenberatung<br/>
        Kenntnisse zu den Hygienestandards<br/>
        Belastbarkeit<br/>
        Organisationstalent<br/>
        Zuverlässigkeit<br/>
        Fähigkeit auf Menschen einzugehen<br/>
        Kommunikationsfähigkeit<br/>
        gastronomisches Denken<br/>
        Dienstleistungsbewusstsein</p>
        <br/>
        <p className={styles.jobHeader}>Was Sie von uns erwarten dürfen:</p>
        <p>
        eine abwechslungsreiche Arbeit bei ausgeglichener Work Life Balance<br/>
        eine leistungsgerechte Entlohnung<br/>
        ein tolles und motiviertes Team<br/>
        Wenn Ihnen der Service am Kunden sehr am Herzen liegt<br/>
         und Sie sich in der Gastronomie zu Hause fühlen, <br/>
        dann sollten wir uns in einem Gespräch näher kennen lernen.<br/>
        </p>

        <h3 className={styles.finish}>Wir freuen uns auf Sie.</h3>






        </div>
      </div>
    );
  }
}

export default Jobs;
