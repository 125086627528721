import React, { Component } from 'react';
import styles from './Kontakt.module.scss';
import Helmet from 'react-helmet';
import { Hero } from './components'


const seo = {
  titleDE:        <title> Kontaktieren Sie uns </title>,
  descriptionDE:  <meta name="description" content='Wir sind jederzeit für Sie da. Telefonisch, per Email oder auch über unser Kontaktformular.' />,
}

class Kontakt extends Component {

  componentDidMount() {
    window.scroll(0,0)
  }

  renderHelmet = () => {
    return (
      <Helmet htmlAttributes={{ lang: 'de'}}> {seo.titleDE} {seo.descriptionDE} </Helmet>
    )
  }

  render() {

    return (
      <div className={styles.background}>
        {this.renderHelmet()}

        <div className={styles.header}>
          <div className={styles.wrapper}>
            <h1>Kontaktieren Sie uns!</h1>
            <p>Wir freuen uns darauf von Ihnen zu hören.</p>
          </div>
        </div>

        <Hero />
        
      </div>
    );
  }
}

export default Kontakt;