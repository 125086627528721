import { Row, Col, Button } from "antd"
import React, { Component } from "react"
import styles from "./hero.module.scss"

const content = {
  header: "Unsere Spezialitäten",
  text: "Bei uns finden Sie neben der regionalen Küche auch frische Vegane Gerichte, zahlreiche kroatische Spezialitäten und viele weitere deftige sowie süße Köstlichkeiten. Lassen Sie sich geschmacklich verwöhnen.",
  button: "Auszug der Speisekarte",
  download: "Zur Speisekarte",
}

class hero extends Component {
  scroll() {
    const elm = document.getElementById("spezialitaeten")
    elm.scrollIntoView({
      behavior: "smooth",
      block: "start",
    })
  }

  render() {
    return (
      <div className={styles.background}>
        <Row type="flex" align="middle" className={styles.wrapper}>
          <Col xs={24} sm={16} lg={16} className={styles.content}>
            <h1 className={styles.headline}>{content.header}</h1>
            <p className={styles.text}>{content.text}</p>
            <Button
              type="primary"
              size="large"
              href="https://sonne-haldenwang.s3.eu-central-1.amazonaws.com/Speisekarte+-+Landgasthof+Sonne+Haldenwang.pdf"
              target="_blank"
              className={styles.button}
            >
              {content.download}
            </Button>
            {/* 
            <p>
              <Button
                size="large"
                onClick={this.scroll}
                className={styles.button}
              >
                {content.button}
              </Button>
            </p> */}
          </Col>
        </Row>
      </div>
    )
  }
}

export default hero
