import React, { Component } from "react"
import styles from "./Spezialitaeten.module.scss"
import Helmet from "react-helmet"
import { Hero, Speisen } from "./components"

const seo = {
  titleDE: <title> Unsere Spezialitäten </title>,
  descriptionDE: (
    <meta
      name="description"
      content="Bei uns finden Sie neben der regionalen Küche auch frische Vegane Gerichte, zahlreiche kroatische Spezialitäten und viele weitere deftige sowie süße Köstlichkeiten."
    />
  ),
}

class Spezialitaeten extends Component {
  componentDidMount() {
    window.scroll(0, 0)
  }

  renderHelmet = () => {
    return (
      <Helmet htmlAttributes={{ lang: "de" }}>
        {" "}
        {seo.titleDE} {seo.descriptionDE}{" "}
      </Helmet>
    )
  }

  render() {
    return (
      <div className={styles.background}>
        {this.renderHelmet()}

        <Hero />
      </div>
    )
  }
}

export default Spezialitaeten
