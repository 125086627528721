import React, { Component } from 'react';
import styles from './hero.module.scss';
import { Row, Col, Button } from 'antd';
import { ContactForm } from './../../../../components';

class Hero extends Component {

  render() {

    return (
      <section className={styles.hero}>
        <Row 
        type='flex' align='middle' 
        className={styles.wrapper}>
          <Col 
          xs={24} sm={16} lg={10}
          className={styles.content}>
            <p className={styles.text}>
              Sehr gerne beantworten wir Ihnen Ihre Fragen.<br/>
              Wir sind jederzeit für Sie erreichbar. <br/><br/> 
              Sie können uns entweder telefonisch, per e-mail oder über unser Kontakformular kontaktieren.<br/><br/>
              <strong>Telefon:</strong> +49 (0)8374/2323811<br/>
              <strong>E-Mail:</strong> <a href='mailto:info@sonne-haldenwang.de'>info@sonne-haldenwang.de</a>
              <br/><br/>
              Wir freuen uns darauf von Ihnen zu hören!
            </p>
          </Col>
          <Col 
          xs={24} sm={16} lg={{span: 12, offset: 2}}
          className={styles.image}>
            <ContactForm />
          </Col>
        </Row>
      </section>
    );
  }
}

export default Hero;
