import React, { Component } from 'react';
import styles from './location.module.scss';

export class Location extends Component {
  render() {
    return (
      <div className={styles.wrapper}>
        <iframe className={styles.map}
        src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJm85azhB3nEcRbisEqc_ArFM&key=AIzaSyDas8fbDRR4HgEGEphdEUtxumb08X0wG88" 
        ></iframe>
      </div>
    );
  }
}

export default Location;