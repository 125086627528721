import store from './../stores/store';
import history from './History';

// Initialize Google Analytics if consent is given

export const initializeGA = () => {
  if( store.cookies.enabled ) {
    window.ga('create', '', 'auto');
    window.ga('set', 'anonymizeIp', true);
    setPageAndSendToGA();
    listenToRouteChanges();
  } 
};

// Listen to route changes

const listenToRouteChanges = () => {
  history.listen((location) => {
    const url = location.pathname
    setPageAndSendToGA(url)
  })
}

// On Route change, set Page and send to GA for tracking

export const setPageAndSendToGA = (url) => {
  window.ga('set', 'page', url);
  window.ga('send', 'pageview');
};