import React, { Component } from 'react';
import styles from './Gallery.module.scss';
import Helmet from 'react-helmet';
import Masonry from 'react-masonry-component';
import { Loading } from './../../components';


import one from './../../assets/gallery/one.jpg';
import two from './../../assets/gallery/two.JPG';
import three from './../../assets/gallery/three.JPG';
import four from './../../assets/gallery/four.JPG';
import five from './../../assets/gallery/five.JPG';
import six from './../../assets/gallery/six.JPG';
import seven from './../../assets/gallery/seven.JPG';
import eight from './../../assets/gallery/eight.JPG';
import nine from './../../assets/gallery/nine.JPG';
import ten from './../../assets/gallery/ten.JPG';
import eleven from './../../assets/gallery/eleven.JPG';
import twelve from './../../assets/gallery/twelve.JPG';
import thirteen from './../../assets/gallery/thirteen.JPG';
import fourteen from './../../assets/gallery/fourteen.JPG';
import fifteen from './../../assets/gallery/fifteen.JPG';
import sixteen from './../../assets/gallery/sixteen.JPG';

const masonryOptions = {
    transitionDuration: 0
};


const seo = {
  titleDE:        <title> Bildergalerie </title>,
  descriptionDE:  <meta name="description" content='Bilder aus dem Landgasthof Sonne in Haldenwang, denn Bilder sagen mehr als Worte.' />,
}

class Home extends Component {
  state = {
    loading: true
  }

  componentDidMount() {
    window.scroll(0,0)
    this.renderGallery()
  }

  renderHelmet = () => {
    return (
      <Helmet htmlAttributes={{ lang: 'de'}}> {seo.titleDE} {seo.descriptionDE} </Helmet>
    )
  }

  renderGallery = () => {
    setTimeout(() => {  
      this.setState({loading: false})
    }, 1500);

  }

  render() {

    return (
      <div className={styles.background}>
        {this.renderHelmet()}
        
        <div className={styles.header}>
          <div className={styles.wrapper}>
            <h1>Bildergalerie</h1>
            <p>... weil Bilder mehr sagen als nur Worte!</p>
          </div>
        </div>
        <div className={styles.divider_left} />
        <div className={styles.wrapper}>
          { 
            !this.state.loading ?
            <Masonry
              className={styles.masonry} // default ''
              elementType={'ul'} // default 'div'
              options={masonryOptions} // default {}
              disableImagesLoaded={false} // default false
              updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
            >
              <img src={one} alt="Landgasthof Sonne Haldenwang" />
              <img src={seven} alt="Landgasthof Sonne Haldenwang" />
              <img src={three} alt="Landgasthof Sonne Haldenwang" />
              <img src={four} alt="Landgasthof Sonne Haldenwang" />
              <img src={five} alt="Landgasthof Sonne Haldenwang" />
              <img src={six} alt="Landgasthof Sonne Haldenwang" />
              <img src={eight} alt="Landgasthof Sonne Haldenwang" />
              <img src={two} alt="Landgasthof Sonne Haldenwang" />
              <img src={nine} alt="Landgasthof Sonne Haldenwang" />
              <img src={ten} alt="Landgasthof Sonne Haldenwang" />
              <img src={eleven} alt="Landgasthof Sonne Haldenwang" />
              <img src={twelve} alt="Landgasthof Sonne Haldenwang" />
              <img src={thirteen} alt="Landgasthof Sonne Haldenwang" />
              <img src={fourteen} alt="Landgasthof Sonne Haldenwang" />
              <img src={fifteen} alt="Landgasthof Sonne Haldenwang" />
              <img src={sixteen} alt="Landgasthof Sonne Haldenwang" />
            </Masonry>
            : <Loading />
          
          }

        </div>
      
      </div>
    );
  }
}

export default Home;
