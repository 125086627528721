import store from './../stores/store';
import { initializeGA } from './Analytics';

// Check state of Cookie Consent

export const checkCookieConsent = () => {
  const name = '_consent' + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      if(c.substring(name.length, c.length) === 'true') {
        store.cookies.enabled = true
        initializeGA()
      }
      else if(c.substring(name.length, c.length) === 'false') {
        store.cookies.enabled = false
      }
    }
  }
  return console.log('No decision made just yet')
}

// Set a Cookie containing the current consent

const setConsentCookie = (consent) => {
  const d = new Date();
  d.setTime(d.getTime() + (730*24*60*60*1000));
  const expires = "expires="+ d.toUTCString();
  document.cookie = '_consent' + "=" + consent + ";" + expires + ";path=/";
  
  checkCookieConsent()
}

// Change Consent on Cookies (If to false --> Reload Page)

export const changeCookieConsent = (consent, reload) => {
  if( consent === true ) {
    setConsentCookie('true')
  }
  else {
    if(document.cookie) {
      const cookies = document.cookie.split(";");
      const domain = window.location.host
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + `=;expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.${domain}; path=/`;
        document.cookie = name + `=;expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=${domain}; path=/`;
      }
    }

    setConsentCookie('false')

    if(reload) {
      document.location.reload()
    }
  }
}