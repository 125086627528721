import React, { Component } from 'react';
import styles from './restaurant.module.scss';
import { Link } from 'react-router-dom';

import image from './../../../../assets/images/gasthof_restaurant.jpg';

// Necessary Imports
import { Row, Col, Button } from 'antd';


class Restaurant extends Component {

  render() {

    return (
      <div id='restaurant' className={styles.background}>
        <Row 
        type='flex' align='middle' 
        className={styles.wrapper}>
          <Col 
          xs={24} sm={16} lg={11}
          className={styles.content}>
            <h2 className={styles.headline}>
              Restaurant
            </h2>
            <p className={styles.text}>
            Unser Restaurant bietet Platz für gemütliche Besuche oder auch für Festlichkeiten, mit Freunden und Verwandten, mit bis zu 80 Personen.
            Wir freuen uns auf Ihren Besuch.
            </p>
            <p className={styles.stube}>
              <span className={styles.stube_span}>Kroatiastube: </span> 
              &nbsp;&nbsp;bis 25 Personen
            </p>
              <p className={styles.stube}>
              <span className={styles.stube_span}>Sonnenstube: </span> 
              &nbsp;bis 35 Personen
            </p>
            <p className={styles.stube}>
              <span className={styles.stube_span}>Große Stube: </span> 
              &nbsp;&nbsp;bis 80 Personen
            </p>
          </Col>
          <Col 
          xs={24} sm={16} lg={{span: 11, offset: 2}}
          className={styles.image}>
            <img src={image} alt='description' />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Restaurant;
 