import { Row, Col, Button } from 'antd';
import React, { Component } from 'react';
import styles from './hero.module.scss';

const content = {
  header: 'Unsere Gästezimmer',
  text: 'Modern eingerichtet, ausgestattet mit Sat-TV, Dusche, WC sowie kostenfreiem Internet. Zudem zentral gelegen und damit der perfekte Ausgangspunkt für Ausflüge jeglicher Art. Bei uns können Sie Ihren Aufenthalt im Allgäu absolut sorgenfrei genießen.',
  button: 'Preise & Details',
}

class hero extends Component {

  scroll() {
    const elm = document.getElementById('zimmer')
    elm.scrollIntoView({
      behavior: "smooth",
      block:    "start",
    })
  }

  render() {

    return (
      <div className={styles.background}>
        <Row 
        type='flex' align='middle' 
        className={styles.wrapper}>
          <Col 
          xs={24} sm={16} lg={16}
          className={styles.content}>
            <h1 className={styles.headline}>
              {content.header}
            </h1>
            <p className={styles.text}>
              {content.text}
            </p>
            <Button 
              type='primary'
              size='large' 
              onClick={this.scroll}
              className={styles.button}>
              {content.button}
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default hero;
 