import { Row, Col, Checkbox, Input, Button, Alert, Icon } from 'antd';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styles from './Form.module.scss';
var SibApiV3Sdk = require('sib-api-v3-typescript');


let apiInstance = new SibApiV3Sdk.TransactionalEmailsApi()

// Configure API key authorization: apiKey

apiInstance.setApiKey(SibApiV3Sdk.AccountApiApiKeys.apiKey, process.env.REACT_APP_SB);







const { TextArea } = Input;

const content = {
  header:     'Kontaktformular',
  subheader:  'Wir freuen uns schon darauf von Ihnen zu hören.',
  missing:    'Bitte füllen Sie alle Pflichtfelder aus!',
  noConsent:  'Bitte stimmen Sie der Verarbeitung Ihrer Daten zu.',
  consent:    <span>Ich habe die<Link to="/datenschutz"> Datenschutzerklärung</Link> zur Kenntnis genommen. Ich stimme zu, dass meine Angaben und Daten zur Beantwortung meiner Anfrage elektronisch erhoben und gespeichert werden. <strong>Hinweis:</strong> Sie können Ihre Einwilligung jederzeit für die Zukunft per E-Mail an info@sonne-haldenwang.de widerrufen.</span>,
  button:     'Absenden'
}

class ContactForm extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      email: null,
      emailError: false,
      name: null,
      nameError: false,
      phone: ' ',
      message: null,
      messageError: false,
      checked: false,
      checkedError: false,
      loading: false,
      success: false
    };
  }

  changeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value})
  }

  changeConsent = (e) => {
    this.setState({ checked: e.target.checked });
  }

  renderError = () => {
    if ( this.state.nameError || this.state.emailError || this.state.messageError ) {
      return <Alert message={content.missing} type="error" className={styles.alert} showIcon />
    } else if ( this.state.checkedError) {
      return <Alert message={content.noConsent} type="error" className={styles.alert} showIcon />
    }
  }

  // Submit Form including E-Mail Texts

  submitForm = (e) => {
    e.preventDefault();
    this.setState({nameError: false, emailError: false, messageError: false})

    const data = {
      "sender":{
        "email":"info@sonne-haldenwang.de",
        "name":"Landgasthof Sonne Haldenwang",
     },
      "subject":"Kontaktanfrage",
      "htmlContent":`<!DOCTYPE html><html><body>Hallo ${this.state.name},<br/><br/> 
      vielen Dank für Ihre Anfrage über unsere Webseite.<br/>
      Wir werden uns hierzu zeitnah bei Ihnen melden.<br/><br/>
      Ihre Nachricht: <br/>
      ${this.state.message} <br/><br/>
      Ihre Kontaktdaten:
            <table>
              <tr>
                <td>E-Mail:</td>
                <td>${this.state.email}</td>
              </tr>
              <tr>
                <td>Telefon:</td>
                <td>${this.state.phone}</td>
              </tr>
            </table> <br/>
      mit freundlichen Grüßen,<br/>
      Ihr Team vom Landgasthof Sonne Haldenwang</body></html>`,
      "messageVersions":[
        //Definition for Message Version 1 
        {
            "to":[
             {
              "email":"info@sonne-haldenwang.de",
              "name":"Landgasthof Sonne Haldenwang"
           }
            ],
            "subject":"Kontaktanfrage Webseite"
         },
 
         //Definition for Message Version 2 
         {
             "to":[
              {
                "email": this.state.email,
                "name": this.state.name,
             }
             ]
          }
     ]
    }
 
    if (this.state.checked && this.state.email && this.state.message) {
      this.setState({loading: true})

        apiInstance.sendTransacEmail(data)   
      .then(() => {
        this.setState({success: true, loading: false});
        window.scroll(0,0)
        return true
      })
      .catch(err => {
        console.log('error', err);
        return false
      });
    } else {
      if (!this.state.name) { this.setState({nameError: true}) }
      if (!this.state.email) { this.setState({emailError: true}) }
      if (!this.state.message) { this.setState({messageError: true}) }
      if (!this.state.checked) { this.setState({checkedError: true}) }
    }
  }

  render() {

    if (this.state.success) {
      return (
        <div className={styles.success}>
          <Icon type="check-circle" /><br/><br/>
          Vielen Dank für Ihre Nachricht.<br/>
          Wir werden uns schnellstmöglich bei Ihnen melden.
        </div>
      )
    }

    return (
      <div className={styles.background}>

        <div className={styles.header}>
          <h2>{content.header}</h2>
        </div>

        <div className={styles.wrapper}>
          <form className={styles.form}>
            {this.renderError()}
            <Row type='flex' justify='space-between'>
              <Col xs={24}
              className={styles.col}>
                <Input 
                size='large' 
                name='name'
                type='text'
                placeholder='* Ihr Name'
                onChange={this.changeInput}
                />
              </Col>
              <Col xs={24}
              className={styles.col}>
                <Input 
                size='large'
                name='email'
                type='email'
                placeholder='* Ihre E-Mail Adresse'
                onChange={this.changeInput}
                />
              </Col>
              <Col xs={24}
              className={styles.col}>
                <Input 
                size='large'
                name='phone'
                type='text'
                placeholder='Ihre Telefonnummer'
                onChange={this.changeInput}
                />
              </Col>
              <Col 
              span={24}
              className={styles.textarea}>
                <TextArea 
                rows={5}
                name='message'
                placeholder='* Ihre Nachricht an uns ...'
                onChange={this.changeInput} 
                />
              </Col>
            </Row>
            <Row 
            type='flex' 
            justify='space-between'
            className={styles.consent}>
              <Checkbox checked={this.state.checked} onChange={this.changeConsent} />
              <p>{content.consent}</p>
            </Row>
            <Row className={styles.button}>
              <Button
              size='large'
              type='primary'
              block
              className={styles.button_inner}
              onClick={this.submitForm}>
                { this.state.loading ? <Icon type="loading" /> : content.button }
              </Button>
            </Row>
          </form>

        </div>
      </div>
    );
  }
}

export default ContactForm; 